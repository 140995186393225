<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="BlastX Analysis" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/lroom-min.png"
              width="307"
              height="208"
              alt=""
              title="lroom"
              class="img-responsive wp-image-12108"/></span
          ><span
            class="fusion-imageframe imageframe-none imageframe-2 hover-type-none"
            ><img
              src="@/assets/RiskManagement/ventedroom-min.png"
              width="307"
              height="269"
              alt=""
              title="ventedroom"
              class="img-responsive wp-image-12109"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) is experienced in utilizing
            DoD BlastX software to analyze the deflagration and/or detonation of
            energetic materials in multi-room structures for shock wave
            reflection and gas pressures.
          </p>
          <p>
            A maximum credible event (MCE) is determined for the room or
            building using established hazards analysis methodologies. The room
            is then modeled in BlastX, including openings, wall construction,
            MCE charge weights, and MCE charge locations. BlastX can be used to
            model rooms that are parallelepiped (6 walls, rectangular), L-shaped
            (8 walls), regular and irregular cylinders (up to 30 walls each),
            general (at least 1 wall), and tunnel venting.
          </p>
          <p>
            By default, the construction of the rooms are considered robust
            (indestructible). “Openings” within the room model are used to
            specify the construction of walls and openings that can fail
            (destructible). Wall failure can be specified using either the
            FACEDAP code (open, concrete 2-way slab, masonry
            reinforced/unreinforced one-way or two-way walls, metal stud walls,
            or glass) or user defined (pressure-time or impulse-pressure
            models). Openings are also used by BlastX to define how rooms are
            interconnected, enabling analysis of multi-room structures.
          </p>
          <p>
            Charge locations are displayed in the modeled room as a red circle;
            targets (i.e. pressure probes/sensors) appear as blue squares.
            Charge details are customizable, including explosive weight,
            detonation delay, charge shape (spherical or cylindrical), charge
            casing, initial weight burned and burn rate, charge composition, and
            parameters for evaluating sympathetic detonation. Multiple charges
            can be specified in each room.
          </p>
          <p>
            Once all parameters have been specified, BlastX analyzes the
            specified configuration for shock reflections and/or venting.
          </p>
          <p>
            In conjunction with BlastX, SMS is experienced in using TM 5-1300 /
            UFC 3-340-02, “Structures to Resist the Effects of Accidental
            Explosions” and DOE TIC-11268, “A Manual for the Prediction of Blast
            and Fragment Loadings on Structures” to determine blast, fragment,
            and shock loads. SMS also has experience using many other computer
            simulations, including ConWep, SHOCK, FRANGE, etc. Finite element
            analysis is an additional option.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "DoD BlastX software",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS is experienced in using DoD BlastX software to analyze maximum credible events (MCEs) in a variety of room and building configurations."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
